export function findOfferById(offerId?: string): Promise<CheckoutFromOfferResponse | null> {
    return fetch('/api/offer/' + offerId, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    }).then(res => {
        if (res.ok) {
            return res.json() as Promise<CheckoutFromOfferResponse>;
        } else {
            return null;
        }
    });
}

export type CheckoutFromOfferResponse = { bookingId: string };