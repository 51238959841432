import { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import { RootState } from "../../../state/store";
import PaymentForm from "./PaymentForm";
import { requestPayment } from "./api/PaymentApi";
import OrderOverviewHandler from "./order/OrderOverviewHandler";

interface PaymentHandlerProps {
    onCancel: () => void;
    onError: () => void;
    onComplete: () => void;
}

export default function PaymentHandler(props: PaymentHandlerProps) {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [step, setStep] = useState<'ORDER' | 'PAYMENT'>('ORDER');

    const [paymentId, setPaymentId] = useState<string>("");

    const booking = useSelector((state: RootState) => state.booking);

    function finalizeOrder() {

        requestPayment(booking.id)
            .then(r => {
                if (r.required) {
                    setPaymentId(r.paymentId);
                    setStep('PAYMENT');
                    setIsLoading(false);
                } else {
                    props.onComplete();
                }
            })
            .catch(props.onError);
        setIsLoading(true);
    }

    return <>
        <Loader active={isLoading}>
            {step === 'ORDER' &&
                <OrderOverviewHandler bookingId={booking.id} onSubmit={finalizeOrder} />
            }
            {step === 'PAYMENT' &&
                paymentId !== "" && <PaymentForm onPaymentCompleted={props.onComplete} paymentId={paymentId} />}
        </Loader>
    </>
}