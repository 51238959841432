import { CustomerType } from "../../booking/identification/types/CustomerIdentification";

export interface TimeSlot {
    start: Date;
    end: Date;
    state: 'FREE' | 'RESERVED' | 'BOOKED';
    reservation_ends: Date | null;
    price: number | null;
}

export const findTimeslots = (customerType: CustomerType, date: Date): Promise<TimeSlot[]> => {
    let formattedDate = date.toISOString().split('T')[0];

    return fetch(`/api/largegroup/timeslots?type=${customerType}&date=${formattedDate}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response.status);
        });
}