import { Buttons } from "../../../../components/button/Button";
import { H3 } from "../../../../components/headline/Headline";
import { OrderResponse } from "../api/OrderApi";
import "./OrderOverview.css";
import OrderOverviewTable from "./OrderOverviewTable";

interface OrderOverviewProps {
    order: OrderResponse;
    headline?: string;
    children?: JSX.Element;
    onSubmit?: () => void;
}

export default function OrderOverview({ order, headline = "Bestellübersicht", children, onSubmit }: OrderOverviewProps) {

    return <>
        <div className="orderOverviewForm">
            <H3 text={headline} />
            <OrderOverviewTable order={order} />
            {children}
        </div>
        {onSubmit !== undefined &&
            <Buttons submitButtonText={order.totalPrice > 0 ? 'Bezahlen' : 'Buchen'} submitButtonAction={onSubmit} />
        }
    </>
}