import { LargeGroupTicket, Ticket } from "../../types/Ticket";
import BookingFlow from "../booking/BookingFlow";
import LargeGroupBookingForm, { LargeGroupTicketData } from "./LargeGroupBookingForm";
import LargeGroupFaq from "./faq/LargeGroupFaq";

export interface SubmitLargeGroupTicketRequest {
    start: number;
    end: number;
    attendees: number;
}

export default function LargeGroupFlow() {

    return <>
        <BookingFlow
            headline="Gruppen&shy;buchungen"
            ticket={
                {
                    headline: 'Finde den passenden Termin',
                    handler: (onTicketConfigurationCompleted, selectedCustomerType) =>
                        <LargeGroupBookingForm
                            onComplete={
                                (data: LargeGroupTicketData) => onTicketConfigurationCompleted({
                                    type: 'LargeGroupTicket',
                                    start: data.start.getTime(),
                                    end: data.end.getTime(),
                                    attendees: data.attendees,
                                    additionalCoach: data.additionalCoach
                                } as LargeGroupTicket)
                            }
                            customerType={selectedCustomerType}
                        />,
                    submitCall: {
                        path: 'ticket/largeGroup',
                        mapper: (ticket: Ticket) => {
                            let largeGroupTicket = ticket as LargeGroupTicket;
                            return {
                                start: largeGroupTicket.start,
                                end: largeGroupTicket.end,
                                attendees: largeGroupTicket.attendees,
                                additionalCoach: largeGroupTicket.additionalCoach
                            } as SubmitLargeGroupTicketRequest
                        }
                    }
                }
            }
            customer={
                {
                    types: ['PERSON', 'COMPANY', 'SCHOOL_OR_CLUB']
                }
            }
        />
        <LargeGroupFaq />
    </>

}