import { useEffect, useState } from "react";
import { OrderResponse, getOrder } from "../api/OrderApi";
import CashInVoucherForm from "./CashInVoucherForm";
import OrderOverview from "./OrderOverview";
import "./OrderOverview.css";
import { H3 } from "../../../../components/headline/Headline";

interface OrderOverviewHandlerProps {
    bookingId: string;
    onSubmit: () => void;
}

export default function OrderOverviewHandler(props: OrderOverviewHandlerProps) {

    const [order, setOrder] = useState<OrderResponse | null>(null);

    useEffect(() => {
        if (!order) {
            getOrder(props.bookingId)
                .then(setOrder);
        }
    }, [order, props.bookingId]);

    return <>
        {order !== null &&
            <OrderOverview
                order={order}
                onSubmit={props.onSubmit}>
                <>
                    <H3 text="Online-Gutscheincode einlösen" />
                    <CashInVoucherForm bookingId={props.bookingId} onValidVoucherCode={() => setOrder(null)} />
                </>
            </OrderOverview>
        }
    </>
}