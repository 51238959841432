import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import { ThirdPartyScript } from './components/ThirdPartyScript';
import Complete from './flows/booking/steps/Complete';
import { findCourseCategories, findCourseData, findUpcomingCourses } from './flows/courses/api/CourseApi';
import CourseBookingFlow from './flows/courses/CourseBookingFlow';
import Courses from './flows/courses/Courses';
import CourseCategories from './flows/courses/pages/CourseCategories';
import CourseList from './flows/courses/pages/CourseList';
import LargeGroupFlow from './flows/largeGroup/LargeGroupFlow';
import { findOfferById } from './flows/offers/OfferApi';
import OfferFlow from './flows/offers/OfferFlow';
import VoucherFlow from './flows/voucher/VoucherFlow';
import './index.css';
import ConsentBanner from './sections/consentBanner/ConsentBanner';
import Footer from './sections/footer/Footer';
import Header from './sections/header/Header';
import { store } from './state/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const { REACT_APP_NETS_EASY_SCRIPT_URL } = process.env;

const router = createHashRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "kurse",
    element: <Courses />,
    children: [
      {
        path: "",
        element: <CourseCategories />,
        loader: findCourseCategories
      },
      {
        path: ":segment",
        element: <CourseList />,
        loader: ({ params }) => findUpcomingCourses(params.segment)
      },
      {
        path: ":segment/:reference",
        element: <CourseBookingFlow />,
        loader: ({ params }) => findCourseData(params.reference)
      }
    ]
  },
  {
    path: "gruppenbuchungen",
    element: <LargeGroupFlow />
  },
  {
    path: "gutscheine",
    element: <VoucherFlow />
  },
  {
    path: "abschluss",
    element: <Complete />
  },
  {
    path: "angebot/:id",
    element: <OfferFlow />,
    loader: ({ params }) => findOfferById(params.id)
  }
]);

root.render(
  <>
    <Provider store={store}>
      <ConsentBanner />
      <Header />
      <main className='app-wrapper'>
        <RouterProvider router={router} />
      </main>
      <Footer />
      <ThirdPartyScript src={REACT_APP_NETS_EASY_SCRIPT_URL} id='nets-easy-script' />
    </Provider>
  </>
);
