interface OrderItem {
    reference: string;
    description: string;
    amount: number;
    price: number;
    hint?: string;
    taxrate: number;
}

export interface OrderResponse {
    items: OrderItem[];
    totalPrice: number;
    netTotal: number;
    taxTotal: number;
}

export function getOrder(bookingId: string): Promise<OrderResponse> {
    return fetch(`/api/booking/${bookingId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(r => {
            if (r.ok) {
                return r.json();
            } else {
                return Promise.reject(r.status);
            }
        });
}