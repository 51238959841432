import { OrderResponse } from "../api/OrderApi";

interface OrderOverviewTableProps {
    order: OrderResponse;
}

export default function OrderOverviewTable(props: OrderOverviewTableProps) {
    return <div className="orderOverview">
        <table className="orderTable">
            <thead>
                <tr className="orderTableRow">
                    <th className="orderItem_description">Artikel</th>
                    <th className="orderItem_column">Anzahl</th>
                    <th className="orderItem_column orderItem_column__large">Stk.Preis</th>
                    <th className="orderItem_column">Steuern</th>
                    <th className="orderItem_column">Gesamt</th>
                </tr>
            </thead>
            <tbody>
                {props.order.items.map(item => {
                    return <>
                        <tr className={`orderTableRow ${item.hint ? '' : 'noHint'}`} key={item.reference}>
                            <td className="orderItem_description">{item.description}</td>
                            <td className="orderItem_column">{item.amount}</td>
                            <td className="orderItem_column orderItem_column__large">{item.price} €</td>
                            <td className="orderItem_column">{item.taxrate} %</td>
                            <td className="orderItem_column">{item.price * item.amount} €</td>
                        </tr>
                        {
                            item.hint &&
                            <>
                                <tr key={item.reference + '_hint'} className="orderItem_hint">
                                    <td colSpan={3}>{item.hint}</td>
                                </tr>
                            </>
                        }
                    </>
                })}
            </tbody >
        </table >
        <div className="orderTotal">
            Summe: <span className="orderTotalValue">{props.order.totalPrice} €</span>

        </div>
        <span className="orderTotal_info">USt: {props.order.taxTotal.toFixed(2)} €</span>
        <span className="orderTotal_info">Netto: {props.order.netTotal.toFixed(2)} €</span>
    </div >
}