import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { H3 } from "../../components/headline/Headline";
import Loader from "../../components/loader/Loader";
import { requestPayment, RequestPaymentResponse } from "../booking/payment/api/PaymentApi";
import OrderOverviewHandler from "../booking/payment/order/OrderOverviewHandler";
import PaymentForm from "../booking/payment/PaymentForm";
import { CheckoutFromOfferResponse } from "./OfferApi";
import CustomerIdentificationForm from "../booking/identification/CustomerIdentificationForm";
import { addCustomerToBooking } from "../booking/api/BookingApi";

type OfferFlowSteps = 'OFFER_OVERVIEW' | 'CUSTOMER_DATA' | 'PAYMENT';

export default function OfferFlow() {

    const checkoutData: CheckoutFromOfferResponse = useLoaderData() as CheckoutFromOfferResponse;

    const [step, setStep] = useState<OfferFlowSteps>('OFFER_OVERVIEW');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentResponse, setPaymentResponse] = useState<RequestPaymentResponse | null>(null);

    function getComponentToRender(): JSX.Element {
        switch (step) {
            case 'OFFER_OVERVIEW':
                return renderOrderOverview();
            case 'CUSTOMER_DATA':
                return <CustomerIdentificationForm
                    customerType="PERSON"
                    onComplete={(customer) => {
                        addCustomerToBooking(checkoutData.bookingId, customer)
                            .then(() => setIsLoading(true))
                            .then(() => requestPayment(checkoutData.bookingId))
                            .then(setPaymentResponse)
                            .then(() => setIsLoading(false))
                            .then(() => setStep("PAYMENT"))
                            .catch(error => console.error(error));
                    }} />
            case "PAYMENT":
                return paymentResponse != null ? <PaymentForm paymentId={paymentResponse.paymentId} onPaymentCompleted={() => setStep('OFFER_OVERVIEW')} /> : <></>
        }
    }

    function renderOrderOverview(): JSX.Element {
        if (checkoutData !== null) {
            return <>
                <OrderOverviewHandler
                    bookingId={checkoutData.bookingId}
                    onSubmit={() => setStep('CUSTOMER_DATA')}
                />
            </>
        }
        return <>
            <H3 text="Angebot nicht gefunden" />
            <div>
                <p>Wir konnten kein Angebot unter diesem Link finden. Bitte wende Dich an das CAP Team</p>
            </div>
        </>
    }

    return <>
        <Loader active={isLoading}>
            {getComponentToRender()}
        </Loader>
    </>
}